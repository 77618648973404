import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import IphoneSrImages from "./pages/IphoneSrImages";
import IphoneEnImages from "./pages/IphoneEnImages";
import AndroidSrImages from "./pages/AndroidSrImages";
import AndroidEnImages from "./pages/AndroidEnImages";
import ErrorPage from "./pages/404";
import TradeAcademyHome from "./pages/TradeAcademyHome";
import TradeAcademyIosImages from "./pages/TradeAcademyIosImages";
import TradeAcademyAndroidImages from "./pages/TradeAcademyAndroidImages";

function App() {
    return (
        <div className="pages">
            <Routes>
                <Route path="*" Component={ErrorPage}/>
                <Route path="/" Component={Home}/>
                <Route path="/en">
                    <Route path="/en/ios-images" Component={IphoneEnImages}/>
                    <Route path="/en/android-images" Component={AndroidEnImages}/>
                </Route>
                <Route path="/sr">
                    <Route path="/sr/ios-images" Component={IphoneSrImages}/>
                    <Route path="/sr/android-images" Component={AndroidSrImages}/>
                </Route>
                <Route path="/trade-akademija" Component={TradeAcademyHome}/>
                <Route path="/trade-akademija/ios-images" Component={TradeAcademyIosImages}/>
                <Route path="/trade-akademija/android-images" Component={TradeAcademyAndroidImages}/>
                {/*<Route path="/trade-akademija/en/ios-images">*/}

                {/*</Route>*/}
                {/*<Route path="/trade-akademija/en/android-images">*/}

                {/*</Route>*/}
                {/*<Route path="/trade-akademija/sr/ios-images">*/}

                {/*</Route>*/}
                {/*<Route path="/trade-akademija/sr/android-images">*/}

                {/*</Route>*/}
            </Routes>
        </div>
    );
}

export default App;
