import React from "react";

function ImageDownload({imageUrl, filename}) {

    const handleImageClick = () => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = filename;
        link.click();
    };

    return (
        <div className="image-component">
            <img src={imageUrl} alt="downloading" onClick={handleImageClick}/>
        </div>
    );
}

export default ImageDownload