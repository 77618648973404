import React from "react";
import ImageDownload from "../components/ImageDownload";
import IphoneImageEn1 from "../assets/iphone-eng/502294154-iphone-12-1170x2532-1-eng.png"
import IphoneImageEn2 from "../assets/iphone-eng/502294204-iphone-12-1170x2532-2-eng.png"
import IphoneImageEn3 from "../assets/iphone-eng/502294252-iphone-12-1170x2532-4-eng.png"
import IphoneImageEn4 from "../assets/iphone-eng/502294316-iphone-12-1170x2532-3-eng.png"

export default function IphoneEnImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={IphoneImageEn1} filename="image.jpg"/>
                    <ImageDownload imageUrl={IphoneImageEn2} filename="image.jpg"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={IphoneImageEn3} filename="image.jpg"/>
                    <ImageDownload imageUrl={IphoneImageEn4} filename="image.jpg"/>
                </div>
            </div>
        </>
    )
}