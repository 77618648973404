import React, {useEffect, useState} from "react";
import IphoneLogo from "../assets/apple.svg"
import AndroidLogo from "../assets/AndroidLogo.svg"
import Isvg from "react-inlinesvg"
import {Link} from "react-router-dom";

export default function TradeAcademyHome() {

    // const storedLanguage = localStorage.getItem("language");
    // const [sr, setSr] = useState(storedLanguage === "SR")
    // const [en, setEn] = useState(storedLanguage === "EN")
    //
    // const handleSelect = (option) => {
    //     setSr(option === "SR");
    //     setEn(option === "EN");
    //     localStorage.setItem("language", option);
    // };
    //
    // useEffect(() => {
    //     localStorage.setItem("language", en ? "EN" : "SR");
    // }, [en]);

    return (
        <div className="home">
            {/*<div className="language-button">*/}
            {/*    <button>*/}
            {/*        <select*/}
            {/*            value={en ? "EN" : "SR"}*/}
            {/*            onChange={(e) => handleSelect(e.target.value)}*/}
            {/*        >*/}
            {/*            <option value="SR">*/}
            {/*                SR*/}
            {/*            </option>*/}
            {/*            <option value="EN">*/}
            {/*                EN*/}
            {/*            </option>*/}
            {/*        </select>*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div className="home-section">
                {/*<Link to={`/trade-akademija/${en ? "en" : "sr"}/ios-images`}>*/}
                <Link to="/trade-akademija/ios-images">
                    <Isvg src={IphoneLogo}/>
                </Link>
                {/*<Link to={`/trade-akademija/${en ? "en" : "sr"}/android-images`}>*/}
                <Link to="/trade-akademija/android-images">
                    <Isvg src={AndroidLogo}/>
                </Link>
            </div>
        </div>
    )
}