import React from "react";
import Isvg from "react-inlinesvg";
import Audio from "../assets/audio.svg"

const AudioDownload = ({audioUrl}) => {

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = audioUrl;
        link.download = "Camel-Dzingl.wav";
        link.click()
    }

    return (
        <button className="audio-download" onClick={handleDownload}><Isvg src={Audio}/></button>
    )
}

export default AudioDownload;