import React from "react";
import ImageDownload from "../components/ImageDownload";
import AndroidImageEn1 from "../assets/samsung-eng/502294163-samsung-s21-a72-1080x2400-1-eng.png"
import AndroidImageEn2 from "../assets/samsung-eng/502294218-samsung-s21-a72-1080x2400-2-eng.png"
import AndroidImageEn3 from "../assets/samsung-eng/502294245-samsung-s21-a72-1080x2400-4-eng.png"
import AndroidImageEn4 from "../assets/samsung-eng/502294281-samsung-s21-a72-1080x2400-3-eng.png"

export default function AndroidEnImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={AndroidImageEn1} filename="image.jpg"/>
                    <ImageDownload imageUrl={AndroidImageEn2} filename="image.jpg"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={AndroidImageEn3} filename="image.jpg"/>
                    <ImageDownload imageUrl={AndroidImageEn4} filename="image.jpg"/>
                </div>
            </div>
        </>
    )
}