import React from "react";
import ImageDownload from "../components/ImageDownload";
import AndroidImageSr1 from "../assets/samsung-srb/502294181-samsung-s21-a72-1080x2400-1.png"
import AndroidImageSr2 from "../assets/samsung-srb/502294234-samsung-s21-a72-1080x2400-2.png"
import AndroidImageSr3 from "../assets/samsung-srb/502294262-samsung-s21-a72-1080x2400-4.png"
import AndroidImageSr4 from "../assets/samsung-srb/502294307-samsung-s21-a72-1080x2400-3.png"

export default function AndroidSrImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={AndroidImageSr1} filename="image.jpg"/>
                    <ImageDownload imageUrl={AndroidImageSr2} filename="image.jpg"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={AndroidImageSr3} filename="image.jpg"/>
                    <ImageDownload imageUrl={AndroidImageSr4} filename="image.jpg"/>
                </div>
            </div>
        </>
    )
}