import React, {useEffect, useState} from "react";
import IphoneLogo from "../assets/apple.svg"
import AndroidLogo from "../assets/AndroidLogo.svg"
import Isvg from "react-inlinesvg"
import {Link} from "react-router-dom";
import AudioFile from "../assets/Camel Dzingl_Final Master_44.1.wav"
import AudioDownload from "../components/AudioDownload";

export default function Home() {

    const storedLanguage = localStorage.getItem("language");
    const [sr, setSr] = useState(storedLanguage === "SR")
    const [en, setEn] = useState(storedLanguage === "EN")

    const handleSelect = (option) => {
        setSr(option === "SR");
        setEn(option === "EN");
        localStorage.setItem("language", option);
    };

    useEffect(() => {
        localStorage.setItem("language", en ? "EN" : "SR");
    }, [en]);

    return (
        <div className="home">
            <div className="language-button">
                <button>
                    <select
                        value={en ? "EN" : "SR"}
                        onChange={(e) => handleSelect(e.target.value)}
                    >
                        <option value="SR">
                            SR
                        </option>
                        <option value="EN">
                            EN
                        </option>
                    </select>
                </button>
            </div>
            <div className="home-section">
                <Link to={`/${en ? "en" : "sr"}/ios-images`}>
                    <Isvg src={IphoneLogo}/>
                </Link>
                <Link to={`/${en ? "en" : "sr"}/android-images`}>
                    <Isvg src={AndroidLogo}/>
                </Link>
                <AudioDownload audioUrl={AudioFile}/>
            </div>
        </div>
    )
}