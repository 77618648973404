import React from "react";
import ImageDownload from "../components/ImageDownload";
import IphoneImage1 from "../assets/trade-academy/Iphone 12 - 1170x2532 1.jpg"
import IphoneImage2 from "../assets/trade-academy/Iphone 12 - 1170x2532 2.jpg"
import IphoneImage3 from "../assets/trade-academy/Iphone 12 - 1170x2532 4.jpg"
import IphoneImage4 from "../assets/trade-academy/Iphone 12 - 1170x2532 3.jpg"

export default function TradeAcademyIosImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={IphoneImage1} filename="image.jpg"/>
                    <ImageDownload imageUrl={IphoneImage2} filename="image.jpg"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={IphoneImage3} filename="image.jpg"/>
                    <ImageDownload imageUrl={IphoneImage4} filename="image.jpg"/>
                </div>
            </div>
        </>
    )
}