import React from "react";
import ImageDownload from "../components/ImageDownload";
import IphoneImageSr1 from "../assets/iphone-srb/502294143-iphone-12-1170x2532-1.png"
import IphoneImageSr2 from "../assets/iphone-srb/502294195-iphone-12-1170x2532-2.png"
import IphoneImageSr3 from "../assets/iphone-srb/502294271-iphone-12-1170x2532-4.png"
import IphoneImageSr4 from "../assets/iphone-srb/502294296-iphone-12-1170x2532-3.png"

function IphoneSrImages() {

    return (
        <div className="images">
            <div className="images-first-row">
                <ImageDownload imageUrl={IphoneImageSr1} filename="image.jpg"/>
                <ImageDownload imageUrl={IphoneImageSr2} filename="image.jpg"/>
            </div>
            <div className="images-second-row">
                <ImageDownload imageUrl={IphoneImageSr3} filename="image.jpg"/>
                <ImageDownload imageUrl={IphoneImageSr4} filename="image.jpg"/>
            </div>
        </div>
    )
}

export default IphoneSrImages