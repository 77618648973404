import React from "react";
import ImageDownload from "../components/ImageDownload";
import AndroidImage1 from "../assets/trade-academy/SamsungS21&A721080x24001.jpg"
import AndroidImage2 from "../assets/trade-academy/SamsungS21&A721080x24002.jpg"
import AndroidImage3 from "../assets/trade-academy/SamsungS21&A721080x24004.jpg"
import AndroidImage4 from "../assets/trade-academy/SamsungS21&A721080x24003.jpg"

export default function TradeAcademyAndroidImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={AndroidImage1} filename="image.jpg"/>
                    <ImageDownload imageUrl={AndroidImage2} filename="image.jpg"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={AndroidImage3} filename="image.jpg"/>
                    <ImageDownload imageUrl={AndroidImage4} filename="image.jpg"/>
                </div>
            </div>
        </>
    )
}